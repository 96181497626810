.ghcardtext {
    color: #fff;
    font-family: 'Cascadia Code', monospace;
  }
/*
.ghcard:hover {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.github-button {
  background-color: #74dbef;
  border-color: #74dbef;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.github-button:hover {
  background-color: white;
  color: #74dbef;
  text-decoration: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-17 0:12:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 /* .ghcard:not(:hover) {
    -webkit-animation: slide-down 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-down 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  } */

 /* @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
   */ 

/* ----------------------------------------------
 * Generated by Animista on 2023-2-17 0:23:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 /* @-webkit-keyframes slide-down {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-down {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
   */

   .github-button {
    background-color: #74dbef;
    border-color: #74dbef;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
  
  .github-button:hover {
    background-color: white;
    color: #74dbef;
    text-decoration: none;
  }
  